<template>
  <div class="input_container">
    <input v-model="comment" type="text" class="input_container__field" placeholder="Busque pelo seu conteúdo..." name="name" id='name' />
  </div>
</template>

<script>
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();

export default {
  data() {
    return {
      comment: ""
    }
  },
  methods: {
    submit() {
      if (this.comment) {
        let idCourse = this.$route.params.curso;
        let idModule = this.$route.params.modulo;
        let idLesson = this.$route.params.aula;
        let data = {
          id: `/${idCourse}/module/${idModule}/lesson/${idLesson}/comment`,
          comment: this.comment,
        };
        serviceCourse
          .postID(data)
          .then(() => {
            this.comment = "";
            this.getComment();
          })
          .catch((err) => console.error(err))
      }

    }
  }
}
</script>

<style>
.input_container {
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
}
.input_container__field {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 50px;
  width: 100%;
  background: #1E2024 !important;
  color: #9A9A9A !important;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
.input_container__field::placeholder {
  color: #9A9A9A;
}
.input_container__button {
  background-color: #3483FA;
  border: none;
  color: #fff;
  padding-left: 20px;
  width: 141px;
  right: 0px;
  position: absolute;
  padding-right: 20px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
</style>