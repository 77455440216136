<template>
  <div class="comments_container">
    <span class="comment_label">Comentários</span>
    <CommentInput />
    <CommentList />
  </div>
</template>

<script>
import CommentInput from './CommentInput/index.vue'
import CommentList from './CommentList/index.vue'

export default {
  components: {
    CommentInput,
    CommentList
  }
}
</script>
<style>
.comment_label {
  color: white;
  margin-bottom: 21px;
}
.comments_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.input_container {
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
}
.input_container__field {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  width: 100%;
  background: #1E2024 !important;
  color: #9A9A9A !important;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
.input_container__field::placeholder {
  color: #9A9A9A;
}
.input_container__button {
  background-color: #3483FA;
  border: none;
  color: #fff;
  padding-left: 20px;
  width: 141px;
  right: 0px;
  position: absolute;
  padding-right: 20px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
</style>