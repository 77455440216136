<template>
  <div class="panda-player-container">
    <video class="panda-player" ref="panda-player" id="panda-player" controls>
      <source src="https://firebasestorage.googleapis.com/v0/b/mb-invest-6509d.appspot.com/o/images%2F2020-11-10-125017461.mp4?alt=media&token=3cdbc690-2d61-4cfa-9eb0-e264544f3d38" type="video/mp4">
    </video>
  </div>
</template>

<script>
  export default {
    props: ['payload'],
    created: {},
  }
</script>

<style scoped>
.panda-player-container {
  display: flex;
  width: 100%;
}
.panda-player {
  display: flex;
  min-width: 100%;
  border-radius: 21px;
}

.player-controls {
  position: relative;
}

</style>