<template>
  <div :class="`player-container player-container__${playerStyle}`">
    <Panda v-if="playerType === 'panda'" :playerPayload="playerPayload" />
    <Audio v-if="playerType === 'audio'" />
    <Quizz v-if="playerType === 'quizz'" />
  </div>
</template>

<script>
import Panda from '@/components/V2/player/playersType/panda/index'
import Audio from '@/components/V2/player/playersType/audio/index'
import Quizz from '@/components/V2/player/playersType/quizz/index'
export default {
  props: ['playerStyle', 'playerType', 'playerPayload'],
  components: {
    Panda,
    Audio,
    Quizz
  }
}
</script>

<style scoped lang="scss">
.player-container {
  display: flex;
  width: 100%;
  background-repeat: no-repeat;
  
  __default {
    display: flex;
    width: 100%;
  }
  
  __theater {
    min-height: 565px;
    display: flex;
    width: 100%;
  }
}
</style>