<template>
  <PandaPlayer v-if="true" />
  <PandaIsLoading v-else />
</template>

<script>
import PandaPlayer from '../panda/panda-player.vue'

export default {
  components: {
    PandaPlayer
  }
}
</script>