<template>
  <iframe
    allow="fullscreen"
    id="panda-player"
    ref="pandaPlayer"
    :src="source"
    style="position: relative; border: 10px; width: 100%; max-width: 980px; height: 100%;"
  ></iframe>
</template>
<script>
import notify from "@/services/libs/notificacao";
export default {
  props: ["source", "porcentagem", "isMobile"],
  data() {
    return {
      height: 500,
      options: {
        muted: false,
        autoplay: true,
      },
      playerReady: false,
      t: "",
      panda_ended: false,
      player: null
    };
  },
  computed: {
    calcHeight() {
      if (this.isMobile) {
        return '250px;'
      } else {
        return '500px;'
      }
    }
  },
  methods: {
    todo(type) {
      //console.log(type);
      if (type === "play") {
        this.$refs.player.play();
      } else if (type === "concluiu") {
        this.$root.$emit("liberaBtn");
      }else if(type === 'calcula'){
				this.$root.$emit('calculaBtn');
			} else if (type === "next") {
        this.$root.$emit("liberaBtnNext");
      } else {
        this.$refs.player.pause();
        //console.log("pauseee");
      }
    },
    async calculaPorcentagem(type) {
      this.player = new PandaPlayer("panda-player");
      setTimeout(() => {
        var current = this.player.currentTime;
        var total = this.player.duration;
        //console.log(current, total)

        current = parseInt(current, 10);
        total = parseInt(total, 10);

        var calc = (current / total) * 100;
        calc = parseInt(calc, 10);

        console.log("Panda Player", current, total, isNaN(calc), this.player);

        if (calc >= parseInt(this.porcentagem, 10) || this.panda_ended) {
          //console.log("emite evento para liberar os btn");
          this.panda_ended = false;
          if (type === "concluir") {
            this.todo("concluiu");
          } else if (type === "calcula") {
            this.todo("calcula");
          } else {
            this.todo("next");
          }
        } else {
          //console.log("caiu no else ??????", calc);
          notify(
            "erro",
            this.$t('player.errors_players.msg3')
          );
        }
      }, 500);
    },
  },
  mounted() {
    this.player = new PandaPlayer("panda-player");
    if (!document.getElementById("fkdjshfkjsdhfkjdsh")) {
      let pandaScript = document.createElement("script");
      pandaScript.setAttribute(
        "src",
        "https://player.pandavideo.com.br/api.js"
      );
      pandaScript.setAttribute("id", "fkdjshfkjsdhfkjdsh");
      document.head.appendChild(pandaScript);
    }

    window.addEventListener(
      "message",
      (event) => {
        const { data } = event;
        // console.log(data);
        if (data.message === "panda_ended") {
          this.panda_ended = true;
        }
      },
      false
    );

    this.$root.$on("clicouConcluir", (data) => {
      if (data === "panda") {
        this.calculaPorcentagem("concluir");
      }
    });
    this.$root.$on("clicouNext", (data) => {
      if (data === "panda") {
        this.calculaPorcentagem("next");
      }
    });
    this.$root.$on("calcula", (data) => {
      if (data === "panda") {
        this.calculaPorcentagem("calcula");
      }
    });
  },
  beforeDestroy() {
    document.getElementById("fkdjshfkjsdhfkjdsh").remove();
    //console.log("beforeDestroy");
  },
};
</script>

<style>
.toZindex .playerVimeo {
  z-index: 99999999999999999;
}
iframe {
  max-width: 980px;
  background-repeat: no-repeat;
  background-position: center center;
}

</style>
