<template>
  <transition>
    <div class="tree" :style="indent">
      <div class="item-comentario">
        <div class="meu-perfil">
          <div class="foto" v-if="autor.picture === '' || autor.picture === null"></div>
          <div v-else class="logox">
            <img :src="autor.picture">
          </div>
        </div>
        <div class="item comentario-simples animationMenu2">
          <h6>
            {{ autor.name }}<span>{{ criacao | moment }}</span>
            <span v-if="status === 'draft'">
              {{ $t('player.tree_comentarios.awaiting_moderation') }}
            </span>
          </h6>
          <p class="animationOpacity2">{{ label }}</p>
          <div v-if="textAreaResp">
            <textarea
              v-model="minhaResposta"
              v-on:keyup.enter="resposta(id)"
              id="myComment"
              type="text"
              class="animationOpacity"
              :placeholder="$t('player.placeholder_comentario')"
            ></textarea>
              <button class="btnResp cancelaBtn animationOpacity2" @click.prevent="textAreaResp = false">
                {{ $t('player.tree_comentarios.cancelar') }}
              </button>
              <button class="btnResp animationOpacity2" @click.prevent="resposta(id)">
                {{ $t('player.tree_comentarios.responder') }}
              </button>
          </div>
          <div v-if="!isMobile" class="like-resposta">
            <a v-if="liked === false" @click="likeComent(id)">
              <span class="numberLiked">
                {{ likes_count }}
              </span>
              {{ $t('player.tree_comentarios.curtir') }}
            </a>
            <a v-else @click="removeLikeComent(id)">
              <span class="numberLiked">
                {{ likes_count }}
              </span>
              {{ $t('player.tree_comentarios.descurtir') }}
            </a>
            <a @click="openResp(id)">
              {{ $t('player.tree_comentarios.responder2') }}
            </a>
            <a v-if="permission && status === 'draft'"
              class="btnAprovar"
              @click="aprovComment(id)"
            >
              {{ $t('player.tree_comentarios.aprovar') }}
            </a>
            <a v-if="permission"
              class="btnDeletar"
              @click="deleteComment(id)"
            >
              {{ $t('player.tree_comentarios.deletar') }}
            </a>
            <a v-if="isMine"
             @click="editComment(id, label)"
            >
              {{ $t('player.tree_comentarios.editar') }}
            </a>
          </div>
          <div v-if="isMobile" class="like-resposta">
            <a v-if="liked === false" @click="likeComent(id)">
              <span class="numberLiked">
                {{ likes_count }}
              </span>
              {{ $t('player.tree_comentarios.curtir') }}
            </a>
            <a v-else @click="removeLikeComent(id)">
              <span class="numberLiked">
                {{ likes_count }}
              </span>
              {{ $t('player.tree_comentarios.descurtir') }}
            </a>
            <a @click="openResp(id)">
              {{ $t('player.tree_comentarios.responder2') }}
            </a>
            <a v-if="isMine" @click="editComment(id, label)">
              {{ $t('player.tree_comentarios.editar') }}
            </a>
          </div>
          <div v-if="isMobile" class="like-resposta" :class="{'spaceActionsMobile':isMobile}">
            <a v-if="permission && status === 'draft'"
              class="btnAprovar"
              @click="aprovComment(id)"
            >
              {{ $t('player.tree_comentarios.aprovar') }}
            </a>
            <a v-if="permission"
              class="btnDeletar"
              @click="deleteComment(id)"
            >
              {{ $t('player.tree_comentarios.deletar') }}
            </a>
          </div>
          <div class="ver-respostas">
            <a v-if="!showChildren && nodes.length"
              href=""
              @click.prevent="toggleChildren"
            >
              {{ $t('player.tree_comentarios.ver_respostas') }} {{ nodes.length }} {{ $t('player.tree_comentarios.ver_respostas2') }}
            </a>
            <a v-else
              v-show="nodes.length"
              href=""
              @click.prevent="toggleChildren"
            >
              {{ $t('player.tree_comentarios.ocultar_respostas') }} {{ nodes.length }} {{ $t('player.tree_comentarios.ocultar_respostas2') }}
            </a>
          </div>
          <div v-show="showChildren">
            <TreeComentarios
              v-for="(node, index) in nodes"
              :key="index"
              :nodes="node.replies"
              :label="node.comment"
              :autor="node.member"
              :criacao="node.created_at"
              :isMine="node.is_mine"
              :liked="node.liked"
              :likes_count="node.likes_count"
              :status="node.status"
              :id="`comment-id-${node.id}`"
              :depth="depth + 1"
            />
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>
<script>
import moment from "moment"
import Cookies from 'js-cookie'

import CourseService from "@/services/resources/CourseService"
import CommentService from "@/services/resources/CommentService"

const serviceCource = CourseService.build()
const serviceComments = CommentService.build()

export default {
  props: ["label", "nodes", "depth", "autor", "criacao", "status", "id", "isMine", "liked", "likes_count"],
  data() {
    return { 
      showChildren: false,
      permission:false,
      openRespId:"",
      textAreaResp:false,
      minhaResposta:'',
      animeDelete:false,
      client:{
        width: 0
      }
    }
  },
  name: "TreeComentarios",
  created(){
    window.addEventListener('resize', this.handleResize)
      this.handleResize()
  },
  computed: {
    indent() {
      return { transform: `translate(${this.depth * 5}px)` }
    },
    isMobile() {
      return this.client.width <= 576
    }
  },
  methods: {
    likeComent(id){
      let params = this.$route.params;
      var data = {
        id: params.curso+'/module/'+params.modulo+'/lesson/'+params.aula+'/comment/'+id+'/like'
      }
      this.$root.$emit('loadOn');
      serviceCource
        .updateId(data)
        .then(() => this.$root.$emit('getComments'))
        .catch(err => console.error(err))
        .finally(() => this.$root.$emit('loadOff'))
    },
    removeLikeComent(id){
      let params = this.$route.params;
      var data = {
        id: params.curso+'/module/'+params.modulo+'/lesson/'+params.aula+'/comment/'+id+'/like'
      }
      this.$root.$emit('loadOn')
      serviceCource
        .destroy(data)
        .then(() => this.$root.$emit('getComments'))
        .catch(err => console.error(err)
        .finally(() => this.$root.$emit('loadOff')))
    },
    handleResize() {
        this.client.width = window.innerWidth;
    },
    resposta(idComment){
      let params = this.$route.params;
      var data = {
        id: params.curso+'/module/'+params.modulo+'/lesson/'+params.aula+'/comment',
        comment:this.minhaResposta,
        parent_lesson_comment_id:idComment,
      }
      this.$root.$emit('loadOn');
      serviceCource
        .postID(data)
        .then(() => {
            this.minhaResposta = ""
            this.textAreaResp = false
            this.$root.$emit('getComments')
        })
        .catch(err => console.error(err))
        .finally(() => this.$root.$emit('loadOff'))
    },
    editComment(id, comentario){
      this.$root.$emit('editComments', { id, comentario })
    },
    openResp(id){
      this.openRespId = id;
      this.textAreaResp = true;
    },
    aprovComment(idComment){
      this.$root.$emit('loadOn')
      serviceComments
        .postID({ id: idComment, status: 'published' })
        .then(() => this.$root.$emit('getComments'))
        .catch(err => console.error(err))
        .finally(() => this.$root.$emit('loadOff'))
    },
    deleteComment(id){
      this.animeDelete = true;
      let params = this.$route.params
      this.$root.$emit('loadOn')
      serviceCource
      .destroy(params.curso+'/module/'+params.modulo+'/lesson/'+params.aula+'/comment/'+id)
      .then(() => {
        this.animeDelete = false
        this.$root.$emit('getComments', id)
      })
      .catch(err => console.error(err))
      .finally(() => this.$root.$emit('loadOff'))
    },
    toggleChildren() {
      this.showChildren = !this.showChildren
    },
    getPermission(){
      let permission = Cookies.get('access_level')
      let typeView = Cookies.get('type_student')
      if(permission === "admin" || permission === "owner") {
        if(typeView === 1 || typeView === '1') {
          this.permission = false
          return
        }
        this.permission = true
      } else  {
        this.permission = false
      }
    },
  },
  mounted(){
    this.getPermission()
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY hh:ss")
    }
  }
}
</script>

<style lang="scss" scoped>
.logox {
  width: 45px;
  height: 45px;
  margin-left: -9px;
  box-sizing: border-box;
  overflow: hidden;
  img{
    width: 100%;
    border-radius: 100%;
  }
}

.btnAprovar{
  color: #00E4A0 !important;
}
.btnDeletar{
  color: #FF0C37 !important;
}

textarea {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 0.5px solid #ededf0;
  padding: 5px 0;
  padding-right: 20px;
  font-size: 13px;
}

.spaceActionsMobile{
  margin-top: 10px;
}

.btnResp {
  background: var(--maincolortrans);
  color: var(--maincolor);
  font-weight: 600;
  font-size: 13px;
  border: none;
  border-radius: 3px;
  padding: 12px 25px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.deletion{
  background: #ff0c3985;
  width: 100%;
  border-radius: 3px;
  transition: all 0.5s ease-out;
  opacity: 0;
}

.cancelaBtn{
    margin-right: 20px;
  background: #f7f7f7 !important;
  color: #81858E !important;

}

.tree {
  margin-top: 20px;
  /* border: 1px solid; */
}
.item-comentario{
  display: flex;
}
.item-comentario .meu-perfil {
  margin: 0;
  margin-right: 10px;
}
.meu-perfil .foto {
  width: 35px;
  height: 35px;
  background: #ededf0;
  border-radius: 50%;
}     
.item-comentario + .item-comentario {
  margin: 17px 0;
}

.comentario-simples {
  text-align: initial !important;
  max-width: 85%;
  /* background: silver; */
}
.comentario-simples h6 {
  font-size: 13px;
  color:var(--fontcolor) !important;
}
.comentario-simples h6 span {
  color: #81858e;
  font-size: 12px;
  font-weight: normal;
  margin-left: 20px;
}
.comentario-simples p {
  color: #81858e;
  line-height: 1.5;
  margin: 10px 0 15px 0;
  font-size: 14px;
  word-break: break-word;
}
.comentario-simples a {
  font-size: 12px;
  font-weight: 600;
  color: #81858e;
}
.like-resposta {
  display: flex;
  align-items: center;
}
.like-resposta a + a {
  margin-left: 15px;
  cursor: pointer;
}
.like-resposta a:first-child {
    cursor: pointer;
}
.ver-respostas {
  margin: 10px 0;
}
.ver-respostas a {
  color: var(--maincolor);
  font-size: 14px;
}

/* animações */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>