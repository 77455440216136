<template>
	<div v-html="source"></div>
</template>
<script>
export default {
    props:['source', 'isMobile'],
}
</script>

<style>
iframe {
  max-width: 980px;
	height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>